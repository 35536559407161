/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
}
body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.carousel-inner {
  top: 22%;
}
bs-datepicker-container {
  z-index: 2000;
}
nav {
  font-family: 'Bebas Neue',sans-serif;
  font-size: 30px;
  font-weight: bold;
}
.navbar {
  justify-content: flex-end;
}
.navbar-nav {
  align-items: center;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav
.nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: #fff;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.btn-close {
background: transparent url("assets/icons/close-sharp-white.png") center/1em auto no-repeat
}
.close {
  opacity: 0.7;
}
.bebas-neue-white-font {
  font-family: "Bebas Neue", sans-serif;
  color: #fff;
}

.bebas-neue-black-font {
  font-family: "Bebas Neue", sans-serif;
  color: #2B2B2B;
}

.bebas-neue-black-font-bold {
  font-family: "Bebas Neue", sans-serif;
  color: #2B2B2B;
  font-weight: bold;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(213,60,30,1);
}
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(213,60,30,1);
}
.neuzeit-font {
  font-family: 'Neuzeit Grotesk', sans-serif;
}
.gurmukhi-font {
  font-family: "Gurmukhi MT", sans-serif;
  font-weight: lighter;
}
.navbar-light .navbar-toggler {
  color: #fff;
  border-color: white;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("assets/icons/menu-sharp-white.svg");
}
.carousel-item-next {
  display: flex;
}
.review-carousel-item.carousel-item.active {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.review-carousel-item.carousel-item.active > q {
  font-size: 30px;
  color: #FFFFFF;
  text-align: center;
}
.review-carousel-item.carousel-item > q {
  font-size: 30px;
  color: #FFFFFF;
  text-align: center;
}
.review-carousel-item.carousel-item.active > span {
  font-size: 25px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  color: #FFFFFF;
  text-align: center;
  font-weight: 100;
}
.review-carousel-item.carousel-item > span {
  font-size: 25px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  color: #FFFFFF;
  text-align: center;
  font-weight: 100;
}
.responsive {
  width: 100%;
  height: auto;
}
.logoVideo {
  background-color: #090502;
  width: 100%;
  height: 100%;
}
video {
  width: 100%;
  height: 100vh;
}
.box {
  height: 65%;
}

#carouselExampleCaptions {
  padding: 0 15%;
}

.contact-us-form-wrapper {
  background-image: url('https://lasbrasascateringimages.s3.amazonaws.com/set_table.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
}

.custom-form-control {
  border-bottom: 1px solid #ced4da;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
}

.custom-form-control:focus {
  border-bottom: 1px solid #ced4da;
  border-left: none;
  border-right: none;
  border-top: none;
  box-shadow: none;
}

.no-focus-border:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}

.form-wrapper {
  grid-column: 1;
  display: grid;
  grid-template-rows: 10% 5% 5% 5% 60% 10%;
  grid-row-gap: 5px;
  background-color: #FFFFFF;
  padding: 30px;
}

.form-wrapper > div:nth-of-type(4) {
  font-size: 18px;
}

.form-title {
  grid-row: 1;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}

.form-subtext:nth-of-type(1) {
  grid-row: 2;
}

.form-subtext:nth-of-type(1) {
  grid-row: 3;
}

.form-subtext:nth-of-type(1) {
  grid-row: 4;
}

.form-subtext {
  font-family: 'Neuzeit Grotesk', sans-serif;
  text-align: center;
  font-size: 23px;
  font-weight: lighter;
}

#contact-us-form {
  grid-row: 5;
  display: grid;
  grid-template-rows: 30% 70%;
  grid-template-columns: 49% 49%;
  grid-gap: 10px;
}

#contact-us-form > div:nth-of-type(1) {
  grid-row: 1;
  grid-column: 1;
  padding: 0 15px 0 0;
}

#contact-us-form > div:nth-of-type(2) {
  grid-row: 1;
  grid-column: 2;
  padding: 0 0 0 15px;
}

#contact-us-form> div:nth-of-type(3) {
  grid-row: 2;
  grid-column: 1/3;
}

textarea {
  resize: none;
}

#contact-us-form > div > label {
  font-size: 20px;
}

.submit-form-button {
  grid-row: 6;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.response-modal-padding {
  padding: 50px;
}

.statement-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #707070;
}

.pizza-slice-icon {
  padding-bottom: 20px;
}

.pizza-slice-icon > img {
  height: 50px;
}

.statement-title {
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: bold;
}

.statement-p {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: normal;
}

.footer-wrapper {
  background-color: #2B2B2B;
  display: flex;
  justify-content: space-between;
}
.footer-social-media-wrapper {
  display: flex;
  justify-content: center;
  align-self: center;
}
.footer-social-media-wrapper > div {
  padding: 20px;
}
.footer-social-media-wrapper > div > a > i {
  color: #fff;
  font-size: 50px;
}
.footer-text {
  font-family: 'Bebas Neue', sans-serif;
  color: #FFFFFF;
  align-self: center;
  text-align: center;
}
.lighter-font-weight {
  font-weight: lighter;
}
.bold-font-weight {
  font-weight: bold;
}
#request-booking-form {
  font-family:'Neuzeit Grotesk', sans-serif;
}
.placeholder-font {
  font-family: sans-serif;
}
.required-star { color: red }
.request-a-booking-modal-header { background-color: darkslategrey }
.small-print { font-size: 11px }
.white-font { color: #FFFFFF }
.center-text-align { text-align: center }
.send-button-wrapper { display: flex; justify-content: center }

/* MEDIA QUERIES */
@media all and (min-width: 375px) {
  .navbar-brand {
    display: none;
  }
  .main-page-contact-details {
    display: none;
  }
  .container-wrapper {
    padding: 0;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .box {
    width: 100%;
    border-radius: 0;
    padding: 20px 0;
  }
  .box > div:first-child {
    width: 58%;
  }
  .title {
    font-size: 50px;
  }
  .subtitle {
    font-size: 30px;
    color: #FFF;
    font-weight: 100;
  }
  nav {
    font-size: 30px;
  }
  .welcome-title {
    text-align: center;
    font-weight: 600;
    font-size: 50px;
  }
  .welcome-subtitle {
    font-size: 16px;
    padding: 15px;
    text-align: center;
  }
  .events-boxes-wrapper {
    display: none;
  }
  .event-box {
    width: auto;
  }
  .book-tasting-dark-wrapper > div {
    padding: 55px;
  }
  .clients-favorites-wrapper {
    display: flex;
    flex-direction: column;
  }
  .column {
    flex: 100%;
    max-width: 100%;
  }
  .title-and-about > h1 {
    font-size: 40px;
  }
  .title-and-about > p {
    padding: 30px;
  }
  #bigger-screen-sizes {
    display: none;
  }
  .bigger-screen-sizes {
    display: none;
  }
  .standalone-gallery-dark-wrapper > q {
    font-size: 1.1rem;
    padding: 4% 12%;
  }
  .standalone-gallery-dark-wrapper > span {
    font-size: 1rem;
  }
  .about-us-wrapper-section {
    display: block;
  }
  #king-of-the-grill-wrapper {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
  }
  #king-of-the-grill-wrapper > div > h1 {
    font-family: "Bebas Neue", sans-serif;
    font-weight: bold;
    font-size: calc(35px + (60 - 35) * ((100vw - 375px) / (1920 - 375)));
    text-align: center;
  }
  .king-of-the-grill-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .king-of-the-grill-logo > img {
    height: 30%;
    width: 30%;
  }
  #king-of-the-grill-wrapper > div > p {
    font-size: calc(12px + (25 - 12) * ((100vw - 375px) / (1920 - 375)));
    padding: 40px;
    text-align: center;
    font-family: 'Neuzeit Grotesk', sans-serif;
    font-weight: lighter;
  }
  .gallery-view-kotg {display: none;}
  .centered-KOTG-logo {display: none;}

  .trophy-list-wrapper {
    padding: 50px;
    margin: auto;
    grid-column: 1/3;
  }

  .trophy-list-wrapper > div {
    text-align: center;
  }

  .trophy-list-wrapper > div > img {
    width: 40px;
  }

  .trophy-list-wrapper > ul {
    font-size: 30px;
    list-style: none;
    font-family: 'trajan-pro-3', serif;
    font-weight: 400;
    font-style: normal;
  }

  .trophy-list-wrapper > ul > li {
    padding: 15px;
  }

  .contact-us-form-wrapper {
    padding: 0;
    grid-template-columns: 100%;
  }
  .statement-wrapper {
    padding: 15% 7%;
    text-align: center;
  }
  .statement-title {
    font-size: 35px;
    font-weight: normal;
  }
  .statement-p {
    font-size: 20px;
    line-height: 1.8;
    font-weight: 200;
  }
  .footer-wrapper {
    display: flex;
    flex-wrap: wrap;
    /*flex-direction: column;*/
  }
  .footer-block {
    flex: 100%;
    max-width: 100%;
  }
  .footer-social-media-wrapper {
    padding: 30px;
  }
  .footer-social-media-wrapper > i {
    font-size: 60px;
    padding: 20px;
  }
  .footer-text {
    padding: 20px;
  }
}
@media all and (min-width: 445px) {
  .standalone-gallery-dark-wrapper > q {
    font-size: 1.3rem;
    padding: 4% 12%;
  }
  .standalone-gallery-dark-wrapper > span {
    font-size: 1.2rem;
  }
}
@media all and (min-width: 554px) {
  .box > div:first-child {
    padding-left: 20px;
    padding-right: 20px;
  }
  .event-box {
    /*width: 80%;*/
  }
  .standalone-gallery-dark-wrapper > q {
    font-size: 1.7rem;
    padding: 4% 15%;
  }
  .standalone-gallery-dark-wrapper > span {
    font-size: 1.6rem;
  }
}
@media all and (min-width: 576px) {
  video {
    display: none;
  }
  .carousel-view-kotg {
    display: none;
  }
  .gallery-view-kotg {
    display: flex;
    position: relative;
  }
  .gallery-view-kotg > div:nth-of-type(1) {
    display: flex; flex-wrap: wrap; padding: 0 30px;
  }
  .king-of-the-grill-img-wrapper {
    flex: 50%; max-width: 50%
  }
  .king-of-the-grill-img-wrapper > img {
    height: 100%;
  }
  /*.footer-wrapper {flex-direction: row-reverse}*/
  .footer-block {
    flex: 50%;
    max-width: 50%;
  }
  .lighter-font-weight {
    font-size: 1rem;
  }
  .bold-font-weight {
    font-size: 1.5rem;
  }
  .footer-social-media-wrapper {
    padding: 0;
  }
  .footer-social-media-wrapper > i {
    font-size: 40px;
  }
}
@media all and (min-width: 768px) {
  .main-page-contact-details {
    display: flex;
  }
  .container {
    padding-bottom: 2.5rem;
  }
  .container-wrapper {
    padding: 0 1rem
  }
  .box { width: 100% }
  .box > div:first-child {
    width: 39%;
    padding-left: 0;
    padding-right: 25px;
  }
  .events-boxes-wrapper {
    display: flex;
  }
  #carouselExampleIndicators {
    display: none;
  }
  .event-box {
    width: 45%;
  }
  .welcome-subtitle {
    font-size: 20px;
  }
  .column {
    flex: 50%;
    max-width: 50%;
  }
  .image-gallery {
    display: flex;
  }
  #bigger-screen-sizes {
    display: flex;
  }
  .bigger-screen-sizes {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    background-color: rgba(43,43,43, 0.5);
  }
  .column-2:last-child {
    display: none;
  }
  .column-2 {
    flex: 25%;
    max-width: 25%;
    overflow: hidden;
    padding: 0;
  }
  #reviewsCarouselWithImages {
    display: none;
  }
  .king-of-the-grill-logo > img {
    max-height: 15%;
    max-width: 15%;
  }
  .contact-us-form-wrapper {
    padding: 70px;
  }
  .footer-wrapper {
    flex-direction: row;
  }
  .footer-social-media-wrapper {
    padding: 0 20px;
  }
  .footer-social-media-wrapper > i {
    font-size: 40px;
    padding: 10px;
  }
  .footer-text {
    padding: 10px 10px 0 10px;
  }
  .footer-block {
    flex: 25%;
    max-width: 25%;
  }
  .lighter-font-weight {
    font-size: 1rem;
  }
  .bold-font-weight {
    font-size: 1.5rem;
  }
}
@media all and (min-width: 992px) {
  .site-logo {
    max-height: 9rem;
    max-width: 9rem;
  }
  .navbar-brand {
    display: flex;
  }
  .container-wrapper {
    padding: 0 4rem;
  }
  .box {
    width: 85%;
    padding: 20px 40px;
  }
  .box > div:first-child {
    width: 47%;
  }
  .title {
    font-size: 64px;
  }
  .subtitle {
    font-size: 25px;
  }
  .event-box {
    width: 45%;
  }
  .book-tasting-dark-wrapper > div {
    padding: 100px;
  }
  .column-2:last-child {
    display: flex;
  }
  .column-2 {
    flex: 20%;
    max-width: 20%;
    overflow: hidden;
    padding: 0;
  }
  .about-us-wrapper-section {
    display: flex;
  }
  .meet-the-chef-wrapper {
    flex: 1;
  }
  .chef-image {
    flex: 1;
  }
  .about-chef-text-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 15% 29% 57%;
  }
  .about-chef-text-wrapper > h1 {
    grid-column: 1/3;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-chef-text-wrapper > p:nth-of-type(1) {
    grid-column: 1;
    grid-row: 2/4;
    font-size: calc(10px + (23.5 - 10) * ((100vw - 992px) / (1920 - 992)));
    padding: 15px;
    display: flex;
    align-items: flex-start;
  }
  .about-chef-text-wrapper > p:nth-of-type(2) {
    grid-column: 2;
    grid-row: 2;
    font-size: calc(11px + (23 - 11) * ((100vw - 992px) / (1920 - 992)));
    text-align: center;
    display: flex;
    align-items: flex-start;
  }
  .about-chef-text-wrapper > p:nth-of-type(3) {
    grid-column: 2;
    grid-row: 3;
    font-size: calc(11px + (23 - 11) * ((100vw - 992px) / (1920 - 992)));
    display: flex;
    align-items: flex-start;
  }
  #king-of-the-grill-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .gallery-view-kotg > div {
    padding: 7% 0;
  }
  .gallery-view-kotg > div:nth-of-type(1) {
    padding: 0;
  }
  .kotg-summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .king-of-the-grill-logo > img {
    display: none;
  }
  #king-of-the-grill-wrapper > div > p {
    padding: 15px 21%;
  }
  .centered-KOTG-logo {
    display: block;
    position: absolute;
    top: calc(135px - (135 - 210) * ((992px - 100vw) / (992 - 1920)));
    left: 41%;
  }
  .centered-KOTG-logo-img {
    width: calc(87px + (174 - 87) * ((100vw - 992px) / (1920 - 992)));
  }
  .contact-us-form-wrapper {
    grid-template-columns: 70% 30%;
  }
  .lighter-font-weight {
    font-size: 1.2rem;
  }
  .bold-font-weight {
    font-size: 1.75rem;
  }
  .footer-social-media-wrapper > i {
    font-size: 50px;
  }
}
@media all and (min-width: 1200px) {
  .site-logo {
    max-height: 12rem;
    max-width: 12rem;
    border-radius: 50px;
  }
  .container-wrapper {
    padding: 0 8rem;
  }
  .box > div:first-child {
    width: 35%;
  }
  .clients-favorites-wrapper {
    display: grid;
    grid-template-columns: 29% 70%;
    grid-template-rows: 90% 5%;
    padding: 70px 10px;
  }
  .title-and-about > h1 {
    font-size: 40px;
  }
  .title-and-about > p {
    padding: 10px 30px;
  }
  .column {
    flex: 25%;
    max-width: 25%;
  }
  .contact-us-form-wrapper {
    /*padding: 0;*/
    grid-template-columns: 45% 55%;
  }
  .lighter-font-weight {
    font-size: 1.5rem;
  }
  .bold-font-weight {
    font-size: 2rem;
  }
  .footer-social-media-wrapper > i {
    font-size: 60px;
  }
}

@media (min-width: 1400px) {
  .container {
    padding-bottom: 15.5rem;
  }
  .box > div:first-child {
    width: 33%;
  }
  .event-box {
    width: 25%;
  }
  #king-of-the-grill-wrapper > div > p {
    padding: 8% 21%;
  }
  .contact-us-form-wrapper {
    grid-template-columns: 40% 60%;
  }
}

@media (min-width: 1800px) {
  .title-and-about > p {
    padding: 10px 120px;
  }
  #king-of-the-grill-wrapper > div > p {
    padding: 5% 21%;
    line-height: 45px;
  }
}
